// require('./bootstrap');

window.Vue = require('vue');
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueCountdown from '@chenfengyuan/vue-countdown';
// import Notifications from 'vue-notification'
// import Notification from "./components/Notification.vue"
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('v-select', vSelect)
Vue.component('exchange-component', require('./components/ExchangeComponent.vue').default);
Vue.component('notification', require('./components/Notification.vue').default);
// Vue.use(Notifications)
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
