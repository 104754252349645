<template>
	<!-- Page Loader -->
	
	<div class="tab-content" id="tabs">
		<notification ref="notification"/>
		<div class="tab-pane fade show active" id="step_one" role="tabpanel" aria-labelledby="home-tab">
			<form  v-on:submit.prevent="nextStep">
				<div class="row d-flex">
					<div class="col-md-7 bg-white component p-3 tab-shadow-box">
						<div class="row">
							<div class="col-md-5">
								<h4 class="text-primary font-weight-bold">Send</h4>
								<div class="form-group">
									<label>Currency</label>
									<div class="row">
										<div class="col-10 pr-0 ">
											<v-select  :options="giveCurrencies" label="name" v-model="give" @input="getGive" data-toggle="tooltip" title="search here">
												<!-- <i slot="spinner" class="icon icon-spinner"></i> -->
												<div slot="no-options">No active currency</div>
												<template slot="option"  slot-scope="option" v-if="option" :disabled="true">
												        <img :src="option.icon" sizes="" alt="icon" height="32" width="32">&nbsp;&nbsp;
												        {{ option.name }}
											    </template>
											</v-select>
										</div>
										<div class="col-2 pl-1 pr-0" ><img :src="giveCurrency.icon" height="32" width="32" v-if="giveCurrency.icon"> </div>
									</div>
								</div>
								<div class="form-group">
									<label>amount</label>
									<input type="number" name="give" class="form-control form-control-sm" v-model="request.give_amount" @change="onGiveChange" @keyup="onGiveChange" @keydown="keyDown" data-validation="required" step=any :readonly="request.pay_with_balance==true || receiveCurrency.type=='gift'" required="">
								</div>	
									
							</div>

							<div class="col-md-1 d-flex align-items-center justify-content-center mt-md-5">
								<a href="javascript:void(0)" @click="swap" class="text-primary">
									<i class="fa fa-exchange exchange">
									</i>
								</a>
							</div>
							<div class="col-md-5">
								<h4 class="text-primary font-weight-bold">Receive</h4>
								<div class="form-group">
									<label>Currency</label>
									<div class="row">
											<div class="col-10 pr-0">
												<v-select  :options="receiveCurrencies" label="name" v-model="receive" @input="getReceive" data-toggle="tooltip" title="search here">
													<!-- <i slot="spinner" class="icon icon-spinner"></i> -->
													<div slot="no-options">No active currency</div>
													<template slot="option"  slot-scope="option" v-if="option">
													        <img :src="option.icon" sizes="" alt="icon" height="32" width="32">&nbsp;&nbsp;
													        {{ option.name }}
													    </template>
												</v-select>
											</div>
											<div class="col-2 pl-1 pr-0" ><img :src="receiveCurrency.icon" height="32" width="32" v-if="receiveCurrency.icon"> </div>
									</div>
								</div>
								<div class="form-group">
									<label>amount</label>
									<input type="number" name="receive" class="form-control form-control-sm" v-model="request.receive_amount" @keyup="onReceiveChange" @keydown="keyDown" data-validation="required" step=any required :readonly="receiveCurrency.type=='gift'">
								</div>	
								<div class="row container" v-if="receiveCurrency.type=='gift'">
									<div class="custom-control custom-radio col-4">
										<input type="radio" class="custom-control-input" id="amount-10" name="amount" v-model="request.receive_amount" checked="checked" value="10" @change="onReceiveChange">
										<label class="custom-control-label" for="amount-10">10$</label>
									</div>
									<div class="custom-control custom-radio col-4">
										<input type="radio" class="custom-control-input" id="amount-20" name="amount" v-model="request.receive_amount" value="20" @change="onReceiveChange">
										<label class="custom-control-label" for="amount-20">20$</label>
									</div>
									<div class="custom-control custom-radio col-4">
										<input type="radio" class="custom-control-input" id="amount-25" name="amount" v-model="request.receive_amount" value="25" @change="onReceiveChange">
										<label class="custom-control-label" for="amount-25">25$</label>
									</div>
									<div class="custom-control custom-radio col-4">
										<input type="radio" class="custom-control-input" id="amount-30" name="amount" v-model="request.receive_amount" value="30" @change="onReceiveChange">
										<label class="custom-control-label" for="amount-30">30$</label>
									</div>
									<div class="custom-control custom-radio col-4">
										<input type="radio" class="custom-control-input" id="amount-50" name="amount" v-model="request.receive_amount" value="50" @change="onReceiveChange">
										<label class="custom-control-label" for="amount-50">50$</label>
									</div>
									<div class="custom-control custom-radio col-4">
										<input type="radio" class="custom-control-input" id="amount-100" name="amount" v-model="request.receive_amount" value="100" @change="onReceiveChange">
										<label class="custom-control-label" for="amount-100">100$</label>
									</div>
								</div>
									
							</div>
						</div>
						

						<div class="container d-flex flex-column justify-content-center mt-3">
							<h4 class="text-primary font-weight-bold">Details</h4>
							<div class="form-group details p-2" v-html="order_details">
								{{ order_details }}
								
							</div>
						</div>
						
					</div>
					<div class="col-md-4 bg-white component p-3 ml-md-4 tab-shadow-box mt-4 mt-md-0">
						<div id="overlay" v-if="overlayAcive"></div>
						<h4 class="text-primary font-weight-bold">Inputs</h4>
						<div class="container-fluid">
							<div class="details p-2">
								<!-- <div class="d-flex">
									<div class="detail-key">Rate :</div> <div class="detail-value">{{ giveCurrency?giveCurrency.sell_price:0 }} : {{ receiveCurrency?receiveCurrency.buy_price:0 }}</div>
								</div> -->
								<div class="d-flex">
									<div class="detail-key">Reserve :</div> <div class="detail-value">{{ receiveCurrency?receiveCurrency.stock:0 }}</div>
								</div>
								<div class="d-flex">
									<div class="detail-key">Receiver wallet fee :</div> <div class="detail-value">{{ receiveCurrency?receiveCurrency.buy_fee/100:0 }}</div>
								</div>
								<div class="d-flex">
									<div class="detail-key">Sender wallet fee :</div> <div class="detail-value">{{ giveCurrency?giveCurrency.sell_fee/100:0 }}</div>
								</div>
								<div class="d-flex">
									<div class="detail-key">Our fee :</div> <div class="detail-value">{{ giveCurrency?giveCurrency.site_fee/100:0 }}</div>
								</div>
								<div class="d-flex">
									<div class="detail-key">Min/Max :</div> <div class="detail-value">{{ giveCurrency?giveCurrency.buy_min:0 }} / {{ giveCurrency?giveCurrency.buy_max:0 }}</div>
								</div>
							</div>
						</div>
						<div class="container-fluid mt-3">
							<div class="form-group" v-if="!user">
								<input type="email" name="email" class="form-control" placeholder="Email" data-validation="required" required="" v-model="request.email">
							</div>
							<fieldset v-for="input in inputs" v-if="receiveCurrency.abbreviation==input[0] || receiveCurrency.type==input[0]">
								<div class="form-group" v-for="field in input[1]" v-if="field">
									<input :type="field.type" :name="field.name" class="form-control" :placeholder="field.label" :data-validation="field.validation" v-model="request[field.name]" required="">
								</div>	
							</fieldset>
							<fieldset >
								<div class="custom-control custom-checkbox" v-if="payWithBalance">
								    <input class="custom-control-input" type="checkbox" name="remember" id="remember" value="balance" v-model="request.pay_with_balance" >

								    <label class="custom-control-label" for="remember" v-if="receive_price">
								       Pay with wallet ({{ receive_price }})
								    </label>
								</div>
							</fieldset>
							<fieldset class="mt-1">
								<div class="custom-control custom-checkbox">
								    <input class="custom-control-input" type="checkbox" name="terms" id="terms" value="balance" v-model="request.terms" required="">

								    <label class="custom-control-label" for="terms">
								       I accept <a href="/terms">terms</a> and <a href="/terms">conditions</a>
								    </label>
								</div>
							</fieldset>
							<fieldset>
								<div class="form-group mt-3">
									<button type="submit" class="btn btn-primary" ><i v-if="loading==1" class="fa fa-spinner fa-spin"></i>Place order</button>
								</div>
							</fieldset>
						</div>
						<!-- <div class="container-fluid mt-3">
							<div class="form-group" v-if="!user">
								<input type="" name="email" class="form-control" placeholder="Email" data-validation="required">
							</div>
							<fieldset v-if="request.receive=='wm'">
								<div class="form-group">
									<input type="text" name="wmid" class="form-control" placeholder="wmid" data-validation="required">
								</div>	next_step
next_step
next_step
								<div class="form-group">
									<input type="text" name="wallet_address" class="form-control" placeholder="wallet address" data-validation="required">
								</div>	
							</fieldset>
							<fieldset v-if="request.receive=='pm'">
								<div class="form-group">
									<input type="text" name="wallet_address" class="form-control" placeholder="wallet address" data-validation="required">
								</div>	
							</fieldset>
							<fieldset v-if="request.receive=='paypal'">
								<div class="form-group">
									<input type="email" name="receiver_email" class="form-control" placeholder="paypal email" data-validation="required">
								</div>		
							</fieldset>
							<fieldset v-if="request.receive=='mastercard'">
								<div class="form-group">
									<input type="number" name="mobile" class="form-control" placeholder="mobile" data-validation="required">
								</div>
								<div class="form-group">
									<input type="text" name="card_holder_name" class="form-control" placeholder="card holder name" data-validation="required">
								</div>	
								<div class="form-group">
									<input type="email" name="receiver_email" class="form-control" placeholder="receiver email" data-validation="required">
								</div>					
							</fieldset>
							<fieldset v-if="receive.type=='gift'">
								<div class="form-group">
									<input type="email" name="receiver_email" class="form-control" placeholder="gift receiver email" data-validation="required">
								</div>
							</fieldset>
							<fieldset v-if="receive.type=='coin'">
								<div class="form-group">
									<input type="text" name="wallet_address" class="form-control" placeholder="wallet address" data-validation="required">
								</div>
							</fieldset>
								<button type="submit" class="btn btn-primary" id="next_step">Place order</button>
						</div> -->
					</div>
				</div>
			</form>
		</div> 
		<div class="tab-pane fade" id="step_two" role="tabpanel" aria-labelledby="home-tab">
			<div class="d-flex row">
				<div class="col-md-6 bg-white component p-3 tab-shadow-box">
					<div class="container d-flex flex-column justify-content-center mt-3">
						<div class="d-flex">
							<h5>Details</h5>
							<span class="form-control ml-auto w-auto">waiting for payment <countdown :time="remainingTime">
								  <template slot-scope="props"><span class="p-1 attension">{{ props.hours }}: {{ props.minutes }} : {{ props.seconds }}</span></template>
								</countdown></span>
						</div>
						<div class="form-group p-2">
							order : #{{ order.id }} <br>
							You pay : {{ order.give_curr_amount }} {{ order.give_currency?order.give_currency.abbreviation.toUpperCase():'' }} <br>
							received amount : {{ order.receive_curr_amount }} {{ order.receive_currency?order.receive_currency.abbreviation.toUpperCase():'' }} <br>
							order created at : {{ order.created_at }} <br>

							<div class="mt-3">
								
							</div>
						</div>
						
					</div>

					<div class="container d-flex flex-column justify-content-center mt-3 ">
						<div class="form-group details p-2 attension">
						<h5><i class="fa fa-volume-up"></i>&nbsp;Attention</h5>
							some details
						</div>
					</div>

					<div class="container d-flex flex-column justify-content-center mt-3 ">
						<div class="form-group details p-2 bg-success">
						<h5><i class="fa fa-warning"></i>&nbsp;Warning</h5>
							some details
						</div>
					</div>
				</div>
				<div class="col-md-4 bg-white component p-3 ml-md-4 tab-shadow-box">
					<h3>Payment</h3>
					<div class="container-fluid mt-3">
							Send <strong>0.5 BTC</strong> to this address<br>
							<div class="input-group my-2">
								<input type="text" :value="depositWallet.address" class="p-2" id="wallet_address" readonly="">
								<div class="input-group-append">
								    <a class="input-group-text btn btn copy " href="javascript:void(0)" id="basic-addon2" @click="copyToClipboard" data-target="#wallet_address">copy</a>
								</div>
							</div>
							Or scan qrcode  
							<div class="my-5 d-flex justify-content-center">
								<div>
									<qrcode-vue :value="depositWallet.address" size="150"></qrcode-vue>	
								</div>
							</div>
							
					</div>
					<div class="d-flex justify-content-center">
						
							<button class="btn btn-danger"><i class="fa fa-trash-o"></i>&nbsp;<strong>delete order</strong></button>
						
					</div>
				</div>

			</div>
		</div>
		<!-- Setup New Project -->
		<div class="modal fade new-project-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" id='loginModal'>
		    <div class="modal-dialog" role="document">
		        <div class="modal-content">
		            <div class="modal-header">
		                <h5 class="modal-title" id="exampleModalLabel">Login</h5>
		                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		                    <span aria-hidden="true">&times;</span>
		                </button>
		            </div>
		            <div class="modal-body">
		                <div class="form-group mb-3">
		                	<label>Email</label>
		                    <input type="email" class="form-control" placeholder="Email">
		                </div>
		                <div class="form-group mb-3">
		                	<label>Password</label>
		                    <input type="password" class="form-control" placeholder="password">
		                </div>
		                <div class="d-flex justify-content-between">
			                <div class="form-check">
			                    <input class="form-check-input" type="checkbox" name="remember" id="remember">

			                    <label class="form-check-label" for="remember">
			                        Remember me
			                    </label>
			                </div>
		                	<div class="form-group mb-3">
		                		<a href="">Forget password?</a>
		                	</div>		                	
		                </div>

		            </div>
		            <div class="modal-footer">
		                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
		                <button type="button" class="btn btn-primary">Login</button>
		            </div>
		            
		        </div>
		    </div>
		</div>
	</div>

</template>
<script>
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import axios from 'axios'
	import QrcodeVue from 'qrcode.vue'
	import sampleInputs from './inputs'
	import orderTemplate from "./order"
	import depositWalletTemplate from "./depositWalletTemplate"
	import Notification from "./Notification"
	export default {
		props:['baseUrl','user','giveDefault','receiveDefault','isDeposit','details'],
		components:{
			'vue-select':vSelect,
			'qrcode-vue':QrcodeVue,
			'notification':Notification
		},
		data(){
			return {
				currencies:[],
				urls:{
					'currency_index':'currency/index',
					'change':'currency/change',
					'receivePrice':'currency/recieve-price',
					'placeOrder':'order/create',
					'active_order':'order/active'
				},
				currencies:[],
				give:[],
				receive:[],
				loginModal:0,
				modalClass:'',
				receive_price:'',
				request:{
					give:'',
					receive:'',
					give_amount:'',
					receive_amount:'',
					wallet_address:'',
					email:'',
					receiver_email:'',
					mobile:'',
					card_holder_name:'',
					wmid:'',
					email:'',
					pay_with_balance:'',
					terms:false,
				},
				defaultCurrency:{
					icon:'',
					stock:0,
					sell_price:0,
					buy_price:0,
					sell_fee:0,
					buy_fee:0,
					site_fee:0,
					abbreviation:'',
					name:'',
				},
				typingTimer:'',
				inputsObject:JSON.parse(JSON.stringify(sampleInputs)),
				new_order:[],
				loading:0,
				deposit_wallet:[],
				active_order:{},
				order_details:this.details,
				notify:{
					type:'danger',
					active:true,
					message:'Some Text',
					title:'Error',
				},
				axiosInstance:axios.create({
                	baseURL:this.baseUrl,
                })
			}
		},
		mounted(){
			this.getCurrencies();
			// this.getActiveOrder();
		},
		computed:{
			remainingTime(){
			    var duration=2*60*60*1000;
			    if (this.purchase && this.now) {
			        var now = new Date(this.now.date);
			        var then=new Date(this.purchase.created_at);

			        var nowInMili=(now.getHours()*60*60+now.getMinutes()*60+now.getSeconds())*1000;
			        var thenInMili=(then.getHours()*60*60+then.getMinutes()*60+then.getSeconds())*1000;
			        if (nowInMili>=thenInMili) {
			            var diff=duration-(nowInMili-thenInMili);
			        }else{
			            nowInMili+=24*60*60*1000;
			            var diff=duration-(nowInMili-thenInMili);
			        }
			        // console.log(now - new Date(this.purchase.created_at).getMilliseconds());
			        if (diff>0) {
			            return diff;
			        }
			        return 0;
			    }
			    return duration;
			},
			overlayAcive(){
				if (this.give && this.give != 'undefined' && this.give.length != 0) {
					if (this.give.stock==0 || this.give.sell_status!='active') {
						return 1;
					}
				}
				if (this.receive && this.receive != 'undefined' && this.receive.length != 0) {
					if (this.receive.stock==0 || this.receive.buy_status!='active') {
						return 1;
					}
				}
				return 0;
			},
			giveCurrency(){
				if (this.give && this.give.length!=0 && this.give!='undefined') {
					return this.give;
				}

				return this.defaultCurrency;
			},
			receiveCurrency(){
				if (this.receive && this.receive.length!=0 && this.receive!='undefined') {
					return this.receive;
				}

				return this.defaultCurrency;
			},
			nextStepDisabled(){
				if (!this.request.pay_with_balance &&(!this.request.give_amount || this.request.give_amount<=0)) {
					return 1;
				}
				if  (!this.request.receive_amount || this.request.receive_amount<=0 ) {
					return 1;
				}

				if ((!this.request.pay_with_balance&&!this.request.give) || !this.request.receive || !this.request.terms) {
					return 1;
				}

				if (!this.user && !this.request.email) {
					return 1;
				}
				if (this.inputs) {
					for(let input of this.inputs){
						if (this.receiveCurrency.abbreviation==input[0] || this.receiveCurrency.type==input[0]) {
							for(let field of input[1]){
								if (!this.request[field.name]) {
									return 1;
								}
							}
						}
					}

				}
				return 0;
			},
			inputs(){
				if (this.inputsObject) {
					let vm=this;
					let arr=Object.keys(vm.inputsObject).map(function(key){
								return [key, vm.inputsObject[key]];
							});
					return arr;
				}
				return 0;
			},
			order(){
				if(this.new_order){
					return this.new_order;
				}

				return JSON.parse(JSON.stringify(orderTemplate));
			},
			depositWallet(){
				if (this.depostit_wallet) {
					return this.depostit_wallet;
				}else{
					return JSON.parse(JSON.stringify(depositWalletTemplate));
				}
			},
			giveCurrencies(){
				if (!this.currencies) {
					return [];
				}

				return this.currencies.filter(currency=>(currency.sell_status=="active"));
			},
			receiveCurrencies(){
				if (!this.currencies) {
					return [];
				}
				let remains=this.currencies.filter(currency=>(currency.buy_status=="active"));
				if (this.isDeposit) {
					remains=remains.filter(currency=>currency.abbreviation=="balance");
				}
				return remains;
			},
			payWithBalance(){
				return (this.user && this.request.receive!='balance');
			}
		},
		methods:{
			//api
			getCurrencies(){
				this.axiosInstance.get(this.urls.currency_index)
					.then(response=>{
						if (response.data) {
							
							this.currencies=response.data.data;

							this.setDefaults();
						}
					})
					.catch(errors=>{
						alert(errors);
					});
			},

			getActiveOrder(){
				this.axiosInstance.get(this.urls.currency_index)
					.then(response=>{
						if (response.data) {
							this.currencies=response.data.data;
						}
					})
					.catch(errors=>{
						alert(errors);
					});
			},
			change(give,receive,amount,type){
            	let vm=this;
            	this.axiosInstance.get(this.urls.change+'?give='+this.request[give]+'&receive='+this.request[receive]+'&amount='+amount+'&type='+type)
            		.then(response=>{
            			if (response.data) {
            				if (response.data.status=='success') {
	            				vm.request[receive+'_amount']=response.data.data;
	            				if (this.request.receive_amount) {
	            					vm.receivePrice();
	            				}
	            				return true;
            				}
            				if (response.data.status=='error') {
            					this.notification(response.data.message, 'danger');
            					// this.$notify({
            					//   group: 'foo',
            					//   title: 'Failed',
            					//   type:'error',
            					//   text: response.data.message,
            					// });
            				}

            			}
            		})
            		.catch(errors=>{
            			alert(errors);
            		});
            	
            },

            receivePrice(){
            	let vm=this;
            	this.axiosInstance.get(this.urls.receivePrice+'?receive='+this.request.receive+'&amount='+this.request.receive_amount)
            		.then(response=>{
            			if (response.data) {
            				if (response.data.status=='success') {
            					vm.receive_price=response.data.data;
            				}else{
            					this.notification(response.data.message,'danger');
            				}
            			}
            		})
            		.catch(errors=>{
            			alert(errors);
            		});
            },
            placeOrder(){
            	if (this.nextStepDisabled==1 || this.loading==1) {
            		return false;
            	}

            	if (this.request.give==this.request.receive) {
            		this.notification(
            			"Send and Receive currencies can not be the same",
            			'warning',
        				'warn'
            			);
            		return false;
            	}
            	if (this.giveCurrency.type=='transfer')  {

            		if (!this.user ){
            			this.notification("For this service you need to sign up",'danger');
            			
            			return false;
            		}

            		if (!this.user.isVerified ){
            			this.notification("For this service you need to complete your personal information",'warning');
            			
            			return false;
            		}
            		
            	}
            	let vm=this;
            	this.loading=1;
            	this.axiosInstance.post(this.urls.placeOrder,this.request)
            	.then(response=>{
            		let data={};
            		if (data=response.data) {
            			// console.log(data);
            			if(data.status=='success'){
							this.notification(data.message,'success');
        					

        					// this.new_order=data.data.order;
        					// this.deposit_wallet=data.data.deposit_wallet;
        					// $('#step_one').removeClass('active show');
        					// $('#step_two').addClass('active show');
        					if (data.action=='submit_form') {
        						$('.body').append(data.data);

        						// $('.form').submit();
        						  setTimeout(function(){ $('.form').submit(); }, 3000);
        						  return true;
        					}

        					if (data.action=='redirect') {
        						setTimeout(function(){ window.location=data.data }, 3000);
        						return true;
        					}

    						setTimeout(function(){ window.location='/order/edit/'+data.order.id }, 3000);


            			}else{
            				this.notification(data.message,'danger');
            			}
            			vm.loading=0;
            		}
            	}).catch(errors=>{
            		//show error messages
            		vm.loading=0;
            	});
            },
            //end api
			copyToClipboard(event) {
             /* Get the text field */
                var data = $(event.target).data();
                var copyText=$(data.target);
             /* Select the text field */
                copyText.select();

             /* Copy the text inside the text field */
                document.execCommand("copy");58241
            },
            nextStep(event){
            	// event.preventDefault();
            	// if (this.give.type=="currency" && !this.user ) {
            	// 	$('#loginModal').modal();
            	// 	return 0;
            	// }

            	this.placeOrder();
            },
            getGive(event){
            	if (event) {
            		this.request.give=event.abbreviation;
            		this.request.pay_with_balance=false;
            		this.onGiveChange();
            	}
            },
            getReceive(event){
            	if (event) {
            		this.request.receive=event.abbreviation;
            		this.request.pay_with_balance=false;
            		this.onGiveChange();
            	}
            },

            onGiveChange(event){
            	let vm=this;
            	if (!this.request.give || !this.request.receive || !this.request.give_amount || this.request.give_amount<0) {
            		return false;
            	}
            	this.typingTimer = setTimeout(function(){
            	    vm.change('give','receive',vm.request.give_amount,'sell');
            	}, 1000);
            },
            onReceiveChange(event){
            	let vm=this;
            	if (!this.request.give || !this.request.receive || this.request.receive_amount<0) {
            		return false;
            	}
            	this.typingTimer = setTimeout(function(){
            	    vm.change('receive','give',vm.request.receive_amount,'buy');
            	}, 1000);
            },

            keyDown(){
                clearTimeout(this.typingTimer);
            },

            swap(){
            	let temp=this.request.give;
            	if (this.giveCurrency.buy_status=='deactive' || this.receiveCurrency.sell_status=='deactive') {
            		return false;
            	}
            	this.request.give=this.request.receive;
            	this.request.receive=temp;
            	this.request.give_amount=this.request.receive_amount;
            	temp=this.give;
            	this.give=this.receive;
            	this.receive=temp;
            	this.onGiveChange();
            },

            setDefaults(){
            	if (this.isDeposit) {
            		this.receive=this.currencies.find(currency=>currency.abbreviation=='balance');
            		this.request.receive=this.receive.abbreviation;
            	}else if (this.receiveDefault) {
            		for (let currency of this.currencies){
            			if (currency.id==this.receiveDefault) {
            				this.receive=currency;
            				this.request.receive=currency.abbreviation;
            			}
            		}
            	}
            	if (this.giveDefault) {
            		for (let currency of this.currencies){
            			if (currency.id==this.giveDefault) {
            				this.give=currency;
            				this.request.give=currency.abbreviation;
            				// this.request.give_amount=currency.sell_min?currency.sell_min:1;
            				// this.onGiveChange();
            			}
            		}
            	}


            	
            },

            notification(message, type, title) {
            	this.$refs.notification.notify(message,type);
            }

		}
	};
</script>
<style>
	.wizard > .content > .body {
		width: 100%;
		height: 100%;
	}

	.component{
		color: #6c757d;
		border-radius: 20px;
	}
	.component .from-group > label{
		font-weight: bold;
	}

	.component i.exchange {
		font-size:2em;
		color:inherit;
	}

	.details{
        background: #eff3f6;
        min-height: 100px;
        border-radius: 5px;
        font-weight: 500;
    }
	
	.details .detail-value{
		margin-left:auto;
	}

	.attension{
		background: #3490dc;
		color:white;
	}

	.bg-success{
		color:white;
	}
	.tab-shadow-box{
		box-shadow: 2px 6px 20px rgba(178, 178, 178, 0.349019607843137);
	}

	.navbar-nav>li{
	    display: inline-block;
	}
	
	.form-control-sm {
		border-radius: 10px;
	}

	.vs__dropdown-toggle {
		border-radius: 10px;
	}

	.notification {
		position: relative !important;
		width: 100%;
	}

	.notification-wrapper {
		border-radius: 0 20px 0 20px;
	}

	#next_step{
		background-image: linear-gradient(to right, #ff67cb 0%,#ffcc67 99%);
	    border: 0 none;
	    border-radius: 5px;
	    font-size: 16px;
	    font-weight: 500;
	    height: 54px;
	    line-height: 54px;
	    padding: 0 25px;
	    position: relative;
	    text-transform: uppercase;
	    vertical-align: middle;
	    color:white;
	}
	@media (max-width: 768px){
		i.exchange{
			transform: rotate(90deg)
		}
	}

	#overlay {
	  position: absolute; /* Sit on top of the page content */
	  display: block; /* Hidden by default */
	  width: 100%; /* Full width (cover the whole page) */
	  height: 100%; /* Full height (cover the whole page) */
	  top: 0; 
	  left: 0;
	  right: 0;
	  bottom: 0;
	  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
	  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
	  cursor: pointer; /* Add a pointer on hover */
	  transform: eas
	}


</style>